import { Patient } from '../../api';
import { Tile } from '../../components/Tile.tsx';
import { styled } from 'styled-components';

const PersonalDetailsTile = styled(Tile)`
  margin-top: 40px;
`;

const PersonalDetailsItem = styled.p`
  margin: 8px 0;
  font-size: ${props => props.theme.fontSizes.sm};
`;

export const PersonalDetailsContent = ({ patient }: { patient: Patient }) => {
  return (
    <PersonalDetailsTile title={'Personal Details'}>
      <PersonalDetailsItem>
        Name: {patient.firstName} {patient.lastName}
      </PersonalDetailsItem>
    </PersonalDetailsTile>
  );
};
